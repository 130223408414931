import React from 'react';
import { FaBookOpen, FaTwitter, FaTelegram } from 'react-icons/fa';
import { Link } from 'react-scroll';

const Footer = () => {
    return (
        <footer className="bg-tron-red text-white relative overflow-hidden">
            <div className="bg-tron-pink text-black py-32">
                <div className="container mx-auto px-4">
                    <div className="text-center">
                        <h2 className="text-xl font-normal mb-4 text-tron-red">What is Tron Dawg?</h2>
                        <p className="text-2xl md:text-4xl font-bold max-w-4xl mx-auto leading-tight">
                            Tron Dawg isn't just a tool; it's a revolution in Tron trading.
                            <span className="hidden md:inline"> Are you ready to run with the pack leader?</span>
                        </p>
                    </div>

                    <img
                        src="/left-dog.svg"
                        alt="Left Dog"
                        className="absolute left-0 top-0 w-48 h-auto opacity-25"
                    />
                    <img
                        src="/right-dog.svg"
                        alt="Right Dog"
                        className="absolute right-0 top-0 w-48 h-auto opacity-25"
                    />
                </div>
            </div>

            <div className="container mx-auto px-4 py-32">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Information</h3>
                        <ul className="space-y-2">
                            <li><a href="https://docs.trondawg.app/" className="hover:underline">Docs</a></li>
                            <li>
                                <Link
                                    to="features"
                                    smooth={true}
                                    duration={500}
                                    className="hover:underline cursor-pointer"
                                >
                                    Features
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Help</h3>
                        <ul className="space-y-2">
                            <li><a href="https://t.me/Trondawgsupport" className="hover:underline">Contact Support</a></li>
                            <li><a href="#" className="hover:underline">Privacy Policy</a></li>
                            <li><a href="#" className="hover:underline">Terms & Conditions</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Subscribe to newsletter</h3>
                        <p className="mb-4">Stay ahead with Tron Dawg! Sign up for our newsletter and get the latest updates.</p>
                        <form className="flex">
                            <input
                                type="email"
                                placeholder="Enter email address"
                                className="flex-grow px-4 py-2 rounded-l-md text-black focus:outline-none focus:ring-2 focus:ring-tron-red"
                            />
                            <button
                                type="submit"
                                className="bg-tron-dark text-white px-4 py-2 rounded-r-md hover:bg-opacity-90 transition-colors duration-200"
                            >
                                Sign Up
                            </button>
                        </form>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row justify-between items-center">
                    <p className="mb-4 md:mb-0">© Copyright 2024, All Rights Reserved by Tron Dawg</p>
                    <div className="flex space-x-4">
                        <a href="https://docs.trondawg.app/" target="_blank" rel="noopener noreferrer" className="hover:opacity-80 transition-opacity duration-200">
                            <FaBookOpen size={24} />
                        </a>
                        <a href="https://x.com/trondawgbot" target="_blank" rel="noopener noreferrer" className="hover:opacity-80 transition-opacity duration-200">
                            <FaTwitter size={24} />
                        </a>
                        <a href="https://t.me/+1HJxNmOlH6EyYjNk" target="_blank" rel="noopener noreferrer" className="hover:opacity-80 transition-opacity duration-200">
                            <FaTelegram size={24} />
                        </a>
                    </div>
                </div>
            </div>

            <img
                src="/left-dog-dark.svg"
                alt="Left Dog"
                className="absolute left-0 bottom-0 w-48 h-auto opacity-25"
            />
            <img
                src="/right-dog-dark.svg"
                alt="Right Dog"
                className="absolute right-0 bottom-0 w-48 h-auto opacity-25"
            />
        </footer>
    );
};

export default Footer;