import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navItems = [
    { name: 'Docs', href: 'https://docs.trondawg.app/' },
    { name: 'Features', href: null },
    { name: 'Support', href: 'https://t.me/Trondawgsupport' }
  ];

  const handleGetStarted = () => {
    window.open('https://t.me/trondawgbot', '_blank', 'noopener,noreferrer');
  };

  return (
    <header className="bg-white text-black py-6 relative z-50">
      <div className="container mx-auto px-4">
        <nav className="flex justify-between items-center">
          <motion.img 
            src="/tron-dawg-logo.png" 
            alt="Tron Dawg Logo" 
            className="h-12"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          />
          <div className="hidden md:flex items-center justify-center flex-grow">
            <ul className="flex space-x-8">
              {navItems.map((item, index) => (
                <motion.li 
                  key={item.name}
                  className="font-inter text-lg font-medium hover:text-tron-red transition-colors duration-200 cursor-pointer"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {item.href ? (
                    <a href={item.href} target="_blank" rel="noopener noreferrer">
                      {item.name}
                    </a>
                  ) : (
                    <Link to="features" smooth={true} duration={500}>
                      {item.name}
                    </Link>
                  )}
                </motion.li>
              ))}
            </ul>
          </div>
          <motion.button
            className="hidden md:block border-2 border-tron-red text-tron-red px-6 py-2 rounded-full font-inter text-lg font-medium hover:bg-tron-red hover:text-white transition-colors duration-200 cursor-pointer"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={handleGetStarted}
          >
            Get Started
          </motion.button>
          <button
            className="md:hidden text-tron-red"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </nav>
      </div>
      {/* Mobile menu */}
      <motion.div
        className={`md:hidden bg-white absolute top-full left-0 right-0 overflow-hidden ${isOpen ? 'block' : 'hidden'}`}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 }
        }}
        transition={{ duration: 0.3 }}
      >
        <div className="container mx-auto px-4 py-4">
          <ul className="space-y-4">
            {navItems.map((item) => (
              <li 
                key={item.name}
                className="font-inter text-lg font-medium hover:text-tron-red transition-colors duration-200 cursor-pointer"
                onClick={() => item.name === 'Features' && setIsOpen(false)}
              >
                {item.href ? (
                  <a href={item.href} target="_blank" rel="noopener noreferrer">
                    {item.name}
                  </a>
                ) : (
                  <Link to="features" smooth={true} duration={500}>
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <button 
            className="mt-4 w-full border-2 border-tron-red text-tron-red px-6 py-2 rounded-full font-inter text-lg font-medium hover:bg-tron-red hover:text-white transition-colors duration-200 cursor-pointer"
            onClick={handleGetStarted}
          >
            Get Started
          </button>
        </div>
      </motion.div>
    </header>
  );
};

export default Header;