import React from 'react';
import { motion } from 'framer-motion';
import { LuMail, LuZap, LuBarChart2, LuSmile, LuCommand, LuMessageCircle } from 'react-icons/lu';

const FeatureItem = ({ icon: Icon, title, description }) => (
  <motion.div 
    className="mb-12 md:mb-0 flex flex-col items-center text-center"
    whileHover={{ scale: 1.05 }}
    transition={{ duration: 0.2 }}
  >
    <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mb-4">
      <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
        <Icon className="text-tron-red text-2xl" />
      </div>
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const Features = () => {
  return (
    <section id="features" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h3 className="text-tron-red font-semibold mb-4">Features</h3>
          <h2 className="text-4xl font-bold mb-4">Analytics that feels like it's from the future</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Tron Dawg isn't just a bot; it's your secret weapon in the Tron trading arena. Let's 
            dive into the features that make Tron Dawg the apex predator of the Tron ecosystem:
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureItem 
            icon={LuMail}
            title="Sniping and Liquidity Priority Execution"
            description="In the world of Tron trading, being fast isn't enough - you need to be lightning. Tron Dawg's advanced sniping tools are your backstage pass to the hottest token launches. Our algorithms don't just keep up with the pack; they lead it."
          />
          <FeatureItem 
            icon={LuZap}
            title="SunPump.meme Integration"
            description="Ride the meme coin wave with Tron Dawg's seamless integration with SunPump.meme!"
          />
          <FeatureItem 
            icon={LuBarChart2}
            title="Revenue Sharing Model"
            description="Tron Dawg is committed to rewarding our community members who help grow our ecosystem. Our new revenue sharing model is designed to incentivize referrals and active participation."
          />
          <FeatureItem 
            icon={LuSmile}
            title="Optimized Multi-Wallet Exchange"
            description="Why juggle wallets when you can orchestrate them? Tron Dawg turns multi-wallet management from a circus act into a symphony."
          />
          <FeatureItem 
            icon={LuCommand}
            title="Instantaneous Token Transactions"
            description="In the time it takes to say 'TRX', Tron Dawg has already completed your trade. Our transaction execution is so fast, it makes the Tron blockchain look like it's moving in slow motion."
          />
          <FeatureItem 
            icon={LuMessageCircle}
            title="Configurable Transaction Settings"
            description="We're not just keeping up with the Tron ecosystem; we're pushing it forward. Here's a sneak peek at what's cooking in the Tron Dawg lab."
          />
        </div>
      </div>
    </section>
  );
};

export default Features;